import { request } from "@/request/http";

export default {
  getReportTemplateInfo: (data) => {
    return request("GET", `/index.php/report/getReportTemplateInfo`, data);
  },

  deleteReportTemplate: (data) => {
    return request("GET", `/index.php/report/deleteReportTemplate`, data);
  },

  updateReportTemplate: (data) => {
    return request("GET", `/index.php/report/updateReportTemplate`, data);
  },

  getReportTemplateList: (data) => {
    return request("GET", `/index.php/report/getReportTemplateList`, data);
  },
};