<template>
    <!-- 素材列表页 -->
    <div class="reportTemplate">
        <el-card>
        <!-- 搜索框 -->
        <div class="div-height div">
            <div class="left">
                <el-button
                    class="themeButton"
                    @click="handleOpen('is_add_category', '添加模板')"
                    >添加模板</el-button
                >
                <el-button
                    class="themeButton"
                    @click=" handleOpen('fileSystem','模板附件管理')"
                    >附件列表</el-button>
            </div>
            <div class="expSearch">  
                <el-input
                    clearable
                    placeholder="请输入内容"
                    v-model="input"
                    @clear="searchContent">
                    <el-button slot="append" class="el-icon-search" @click="searchContent" ></el-button>
                </el-input>
            </div>
            <!-- <div class="right">
                <el-input
                    placeholder="请输入内容"
                    v-model="input"
                    clearable
                    class="input-div"
                >
                </el-input>
                <el-button type="primary" @click="searchContent()"
                    >搜索</el-button
                >
            </div> -->
        </div>
        <!-- 表格 -->
        <div class="div">
            <el-table :data="tableData" style="width: 100%">
                <!-- 排序 -->
                <el-table-column type="index" :index="indexMethod" label="序号" width="80" align="center">
                </el-table-column>
                <!-- 试卷名称 -->
                <el-table-column prop="name" label="模板名称">
                </el-table-column>
                <el-table-column  min-width="100" prop="projectList" label="绑定项目">
                </el-table-column>
                <el-table-column  prop="fileNum" label="附件数量">
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作" min-width="150">
                    <template slot-scope="scope">
                        <div class="operateItem">
                            <!-- <div class="operatorItem floatLeft">转让</div> -->
                            <div class="operatorItem floatLeft" @click="handlepreview(scope.row)">预览</div>
                            <div class="operatorItem floatLeft" @click="handleShare(scope.row)">共享</div>
                            <div class="operatorItem floatLeft" @click="editQuestion(scope.row)">编辑模板</div>
                            <div class="operatorItem floatLeft" @click="handleOpen('is_update_category','编辑模板',scope.$index,scope.row)">重命名</div>
                            <div class="operatorDel floatLeft" @click="handleOpen('is_delete_category','删除模板',scope.$index,scope.row)">删除</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页 -->
        <div class="div-height padding-top div">
            <el-pagination
                @current-change="handleCurrentChange"
                background
                layout="prev, pager, next"
                :current-page.sync="page"
                :total="dataTotal"
                v-if="dataTotal != 0"
            >
            </el-pagination>
        </div>
    </el-card>
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="30%"
        >
            <span>
                <span
                    v-if="
                        handleSign == 'is_add_category'  ||
                        handleSign == 'is_update_category'
                    "
                >
                    <el-input
                        v-model="title"
                        placeholder="请输入模板名称"
                    ></el-input>
                </span>

                <span v-else-if="handleSign == 'share'">
                    <span style="font-size: 0.8333vw;">请选择要共享的同事：</span><br><br>
                    <el-select v-model="selectTogether" multiple placeholder="请选择" style="width:100%;">
                        <el-option
                        v-for="item in togetherList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </span>

                <span v-else class="dialog-span">您确定要删除么？</span>
            </span>
            <span
                slot="footer"
                class="dialog-footer"
                v-if="handleSign != 'is_qrcode'">
                <el-button class="themecancelButton" @click="dialogVisible = false">取 消</el-button>
                <el-button class="themeButton" @click="dialogFix()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/css/teacher/report/reportTemplate.scss";
</style>
<style>
.reportTemplate .el-table__body-wrapper{
    height: 65vh !important;
    overflow: scroll!important;
    scrollbar-width: none!important;
    -ms-overflow-style: none!important;
}
.reportTemplate .el-table__body-wrapper::-webkit-scrollbar{
    display: none !important
}
.reportTemplate .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D5570;
    color: #FFF;
}
.reportTemplate .el-table__row{
    /* height: 7vh */
}
.reportTemplate .el-dialog{
    border-radius: 1vw;
}
.reportTemplate .el-dialog__body{
    padding: 1.5625vw 1.0417vw ;
    padding-top: 0.2604vw !important;
    color: #606266;
    font-size: 0.7292vw;
    word-break: break-all;
    padding-bottom: 1.5625vw;
    /* text-align: center; */
}
.reportTemplate .el-table{
    font-size: 0.8333vw !important;
}
.reportTemplate .el-table .el-table__cell{
    padding: .5vw 0 !important;
}
</style>
<script>
import questionBank from "@/assets/js/teacher/report/reportTemplate.js";
export default {
    ...questionBank
};
</script>
