import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { sleep, startLoading, endLoading } from "@/utils/function"; 
import API from "@/api/teacher/report/reportTemplate";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    name: "other",
    components: {},
    inject:['reload'],
    data() {
        return {
            input: "",
            page: 1,
            pagesize:10,
            dataTotal: 0,
            loading: "",
            dialogVisible: false,
            tableData: [],
            title: "",
            exam_paper_id: "",
            
            dialogTitle: "",
            togetherList:[],
            selectTogether:[],
            report_template_id:0,
            handleSign: "",
        };
    },
    created() {
        const self = this;
        self.input = this.$router.currentRoute.query.search;
        self.page = this.$router.currentRoute.query.page?Number(this.$router.currentRoute.query.page):1;
        self.getData();
    },
    methods: 
    {
        handleShare(row)
        {
            const self = this;
            self.handleSign = 'share';
            self.dialogVisible = true;
            self.report_template_id = row.id;
            console.log(row);
        },
        indexMethod(index)
        {
            let currentPage = this.page; 
            let pageSize = this.pagesize; 
            return (index + 1) + (currentPage - 1) * pageSize; 
        },

        handlepreview(previewInfo)
        {
            window.sessionStorage.setItem('content',previewInfo.content)
            window.open("#/teacher/report/preview?name="+previewInfo.name)
        },

        editQuestion(row) 
        {
            let self = this;
            let bank_id = row.id;
            self.$router.push({
                path: "/teacher/reportTemplateUpdate",
                query: {
                    bank_id: bank_id,
                    name:row.name,
                    hideMenu:1,
                    type:1
                }
            }).then(()=>{
                self.reload();
            })
        },

        searchContent() 
        {
            const self = this;
            self.page = 1;
            self.getData();
            this.$router.push({
                path: "/teacher/report/reportTemplate",
                query: {
                    search: self.input
                }
            });
        },

        handleOpen(sign, title = "", index = "", row = "") 
        {
            const self = this;
            self.handleSign = sign;
            self.dialogTitle = title;
            if (sign == "is_delete_category") 
            {
                self.dialogVisible = true;
                self.exam_paper_id = row.id;
            } 
            else if ( sign == "is_add_category" || sign == "is_update_category" ) 
            {
                if (sign == "is_update_category") 
                {
                    self.exam_paper_id = row.id;
                    self.getExamPaperInfo();
                }
                self.dialogVisible = true;
            }
            else if(sign == "fileSystem")
            {
                // window.open("#/teacher/reportTemplateFile?bank_id="+self.bank_id)
                window.open("#/teacher/reportTemplateFile")
            }
        },

        getExamPaperInfo() 
        {
            const self = this;
            let data = {
                exam_paper_id: self.exam_paper_id
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            // 请求数据
            API.getReportTemplateInfo(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.title = result.info.name;
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
        },

        dialogFix() 
        {
            const self = this;
            let data = {
                title: self.title,
                exam_paper_id: self.exam_paper_id
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            if (self.handleSign == "is_delete_category") 
            {
                // 请求数据
                API.deleteReportTemplate(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.getData();
                            self.dialogVisible = false;
                            self.exam_paper_id = "";
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            } 
            else if(self.handleSign == "share")
            {
                let datas = {
                    together_id: self.selectTogether,
                    report_template_id: self.report_template_id
                };
                console.log(datas);
                console.log(self.selectTogether);
                self.axios
                    .post("/index.php/report/shareReportTemplate",datas)
                    .then((result)=>{
                    self.$Loading.hide();
                    nprogressClose();
                    if(result.data.code > 0)
                    {
                        self.dialogVisible = false;
                    }
                    else
                    {
                        self.$Tips({
                        // 消息提示内容
                        message: result.data.msg,
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        // 消失时间
                        displayTime: 1500
                        });return;
                    }
                })
            }
            else 
            {
                // 请求数据
                API.updateReportTemplate(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.getData();
                            self.dialogVisible = false;
                            self.title = "";
                            self.exam_paper_id = "";
                            // Message.success(result.msg);
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
            }
        },

        handleCurrentChange(val) {
            const self = this;
            self.page = val;
            self.getData();
        },

        getData() 
        {
            const self = this;
            let data = {
                page: self.page,
                search: self.input
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            // 请求数据
            API.getReportTemplateList(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.tableData = result.projectList[0].id?result.projectList:[];
                            self.dataTotal = result.count;
                            self.togetherList = result.togetherList;
                            // 路由传参
                            self.$router.push({
                                path: "/teacher/report/reportTemplate",
                                query: {
                                    search: self.input,
                                    page: self.page
                                }
                            });
                        }
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                    })
        }
    }
};
